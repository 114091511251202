import {evaluationApi, ideaApi} from "../../api/api-wrapper";
import {Context} from "@profiscience/knockout-contrib-router";
import {CommentDto, EvaluationDto, IdeaDto} from "../../api/generated";
import {observable} from "knockout-decorators";
import * as ko from "knockout";
import {isIdeaActive} from "../ideas/ideaUtils";
import "../../components/elements/idea/dashboard-list-idea";
import "../../components/elements/cards/card-dashboard-list";
import "../../components/elements/evaluation/dashboard-list-evaluation"
import * as moment from "moment";
import _uniqBy from "lodash-es/uniqBy";

class ViewModelContext extends Context {
    comments: CommentDto[];
    evaluations: EvaluationDto[];
    ideas: IdeaDto[];
}

class ViewModel {

    /**
     * All ideas.
     */
    @observable({deep: false, expose: false})
    public ideas: IdeaDto[];

    /**
     * All evaluations.
     */
    @observable({deep: false, expose: false})
    public evaluations: EvaluationDto[];

    /**
     * The ideas filtered.
     */
    public ideasFiltered: KnockoutComputed<IdeaDto[]>;

    /**
     * The ideas filtered.
     */
    public evaluationsFiltered: KnockoutComputed<EvaluationDto[]>;

    /**
     * Ideas without evaluations.
     */
    public ideasWithoutEvaluation: KnockoutComputed<IdeaDto[]>;

    /**
     * Evaluations where the idea has no certificate.
     */
    public evaluationsWithoutCertificate: KnockoutComputed<EvaluationDto[]>;

    /**
     * Evaluations which are not submitted.
     */
    public evaluationsNotSubmitted: KnockoutComputed<EvaluationDto[]>;

    /**
     * Evaluations which are not evaluated by the admin.
     */
    public evaluationsNotEvaluated: KnockoutComputed<EvaluationDto[]>;

    /**
     * Evaluations which are not evaluated by the admin.
     */
    public evaluationsPostponed: KnockoutComputed<EvaluationDto[]>;

    /**
     * Alle Gutachten, die für Umsetzungsberichte relevant sind.
     * (Idee veröffentlicht und Idee nicht im Status 'Not new' und 'Canceled', Gutachten hat ein Umsetzungsdatum)
     */
    public implementationDescriptions: KnockoutComputed<EvaluationDto[]>;

    /**
     * Evaluations without an implementation description set and where the implementation date is in the past.
     */
    public implementationDescriptionsMissing: KnockoutComputed<EvaluationDto[]>;

    /**
     * Evaluations with an implementation description set and where the idea state is not 'Implemented' or 'Canceled'.
     */
    public implementationDescriptionsApprove: KnockoutComputed<EvaluationDto[]>;

    /**
     * Evaluations which are handed over to a new expert user and not accepted by the admin.
     */
    public handoverEvaluations: KnockoutComputed<EvaluationDto[]>;

    /**
     * Constructor
     * @param ctx
     */
    constructor(ctx: ViewModelContext) {
        this.ideas = ctx.ideas.filter(idea => idea.state != IdeaDto.StateEnum.Canceled
            && idea.state != IdeaDto.StateEnum.Implemented
            && idea.state != IdeaDto.StateEnum.NotNew);
        this.evaluations = ctx.evaluations;
        this.ideasFiltered = ko.pureComputed(() => this.ideas);
        this.ideasWithoutEvaluation = ko.pureComputed(() =>
            this.ideasFiltered()
                .filter(idea =>
                    // Idee soll Dashboard „Ideen ohne Gutachter“ erst verlassen wenn Idee einem Gutachter zugewiesen
                    // wurde UND NICHT im Status "Idee wurde eingereicht" ist
                    // siehe IDW-307
                    idea.state == IdeaDto.StateEnum.Submitted ||
                    (idea.evaluationsCnt == 0 && isIdeaActive(idea) && idea.state != IdeaDto.StateEnum.Implementation)
                )
                .sort((idea1, idea2) =>
                    (<string><any>idea1.created).localeCompare(<string><any>idea2.created))
        );
        this.evaluationsFiltered = ko.pureComputed(() => this.evaluations);
        this.evaluationsNotSubmitted = ko.pureComputed(() =>
            this.evaluationsFiltered()
                .filter(evaluation =>
                    isIdeaActive(evaluation.idea) &&
                    evaluation.submitted == false && evaluation.evaluated == false)
                .sort((eval1, eval2) => {
                    const orderByIdea  = eval1.idea.id - eval2.idea.id;
                    if (orderByIdea !== 0) {
                        return orderByIdea;
                    }

                    const date1 = eval1.postponeTimestamp ? eval1.postponeTimestamp : eval1.created;
                    const date2 = eval2.postponeTimestamp ? eval2.postponeTimestamp : eval2.created;

                    return (<string><any>date1).localeCompare(<string><any>date2)
                })
        );
        this.evaluationsNotEvaluated = ko.pureComputed(() =>
            this.evaluationsFiltered()
                .filter(evaluation =>
                    evaluation.submitted == true &&
                    // IDW-331 Gutachten darf nicht archiviert sein
                    !evaluation.archived &&
                    // Idee soll Dashboard „Freizugebende Gutachten“ erst verlassen wenn Gutachten freigegeben wurde
                    // UND NICHT im Status „In Begutachtung“ ist
                    // siehe IDW-307
                    (evaluation.evaluated == false || evaluation.idea.state == IdeaDto.StateEnum.Evaluation)
                    && isIdeaActive(evaluation.idea))
                .sort((eval1, eval2) => {
                    const orderByIdea  = eval1.idea.id - eval2.idea.id;
                    if (orderByIdea !== 0) {
                        return orderByIdea;
                    }

                    const date1 = eval1.submittedTimestamp ? eval1.submittedTimestamp : eval1.created;
                    const date2 = eval2.submittedTimestamp ? eval2.submittedTimestamp : eval2.created;
                    return (<string><any>date1).localeCompare(<string><any>date2)
                })
        );
        this.evaluationsPostponed = ko.pureComputed(() =>
            this.evaluationsFiltered()
                .filter(evaluation =>
                    evaluation.postponeUntil && !evaluation.postponeAccepted)
                .sort((eval1, eval2) => {
                    // old evaluations (before 1.0.5) do not have a postponed date
                    const date1 = eval1.postponeTimestamp != null ? eval1.postponeTimestamp : eval1.created;
                    const date2 = eval2.postponeTimestamp != null ? eval2.postponeTimestamp : eval2.created;
                    return (<string><any>date1).localeCompare(<string><any>date2)
                })
        );
        this.implementationDescriptions = ko.pureComputed(() =>
            this.evaluationsFiltered()
                .filter(evaluation =>
                    // Idee ist veröffentlicht
                    evaluation.idea.published &&
                    // Idee ist nicht im Status 'wird eingereicht' oder dass sie nicht umgesetzt wird
                    evaluation.idea.state != IdeaDto.StateEnum.Submission &&
                    evaluation.idea.state != IdeaDto.StateEnum.NotNew &&
                    evaluation.idea.state != IdeaDto.StateEnum.Canceled &&
                    // Umsetzungsdatum ist vorhanden
                    evaluation.implementationDate &&
                    // Submitted Datum ist vorhanden (ab 12.12.2019)
                    evaluation.implementationDate
                )
        );
        this.implementationDescriptionsMissing = ko.pureComputed(() =>
            this.implementationDescriptions()
                .filter(evaluation =>
                    // nur aktive Ideen
                    isIdeaActive(evaluation.idea) &&
                    // Gutachten hat noch keinen Umsetzungsbericht
                    (!evaluation.implementationDescription || evaluation.implementationDescription.trim() == "") &&
                    // Umsetzungsbericht noch nicht freigegeben
                    !evaluation.implementationApproved &&
                    // Umsetzungsdatum vorbei
                    moment().isAfter(moment(evaluation.implementationDate)))
                .sort((eval1, eval2) =>
                    (<string><any>eval1.implementationDate).localeCompare(<string><any>eval2.implementationDate))
        );
        this.implementationDescriptionsApprove = ko.pureComputed(() =>
            this.implementationDescriptions()
                .filter(evaluation =>
                    // Gutachten hat einen Umsetzungsbericht
                    evaluation.implementationDescription &&
                    evaluation.implementationDescription.trim() != "" &&
                    // Umsetzungsbericht noch nicht freigegeben
                    // oder Idee ist noch im Status 'in Umsetzung' oder Begutachtung  - siehe IDW-307
                    (!evaluation.implementationApproved ||
                        (evaluation.idea.state == IdeaDto.StateEnum.Evaluation ||
                            evaluation.idea.state == IdeaDto.StateEnum.Implementation)))
                .sort((eval1, eval2) =>
                    (<string><any>eval1.implementationDate).localeCompare(<string><any>eval2.implementationDate))
        );
        this.handoverEvaluations = ko.pureComputed(() =>
            this.evaluationsFiltered()
                .filter(evaluation =>
                    evaluation.expertApproved === false && !evaluation.evaluated)
                .sort((eval1, eval2) =>
                    (<string><any>eval1.timestamp).localeCompare(<string><any>eval2.timestamp))
        );
        this.evaluationsWithoutCertificate = ko.pureComputed(() => {
            const evaluations = this.evaluationsFiltered()
                .filter(evaluation =>
                    // Freigegebene Gutachten mit Ergebnis wird umgesetzt
                    evaluation.submitted &&
                    evaluation.evaluated &&
                    evaluation.implement == EvaluationDto.ImplementEnum.Implement && !evaluation.idea.certificateIssued
                )
                .sort((eval1, eval2) => {
                    // old evaluations (before 1.0.5) do not have a submitted date
                    const date1 = eval1.submittedTimestamp != null ? eval1.submittedTimestamp : eval1.created;
                    const date2 = eval2.submittedTimestamp != null ? eval2.submittedTimestamp : eval2.created;
                    return (<string><any>date1).localeCompare(<string><any>date2)
                });
            return _uniqBy(evaluations, function (evaluation) {
                return evaluation.idea.id;
            });
        });
    }
}

export default <KnockoutLazyPageDefinition>{
    viewModel: ViewModel,
    template: require('./dashboard.html'),
    componentName: "dashboard",
    loader: (ctx: ViewModelContext) => {
        document.title = `${document.title} - Dashboard`;
        return Promise.all([
            ideaApi.getIdeas(null, null, null, null, null, true)
                .then(ideas => ctx.ideas = ideas),
            evaluationApi.getEvaluations(true).then(evaluations => ctx.evaluations = evaluations)
        ]);
    }
};
